<template>
  <div>
    <b-badge :variant="statuses[registration.status_id].variant">
      {{ statuses[registration.status_id].text }}
    </b-badge>
    <h4 class="mt-2">
      Informações Cadastrais
    </h4>
    <b-row class="mt-2">
      <b-col
        md="4"
      >
        <h6> Razão Social: </h6>
        <p> {{ registration.name }} </p>
      </b-col>

      <b-col md="4">
        <h6> Ramo de Atividade: </h6>
        <p> {{ registration.company_type }} </p>
      </b-col>

      <b-col md="4">
        <h6> Faturamento Médio Mensal: </h6>
        <p> {{ registration.monthly_income | value }} </p>
      </b-col>

      <b-col md="4">
        <h6> E-mail: </h6>
        <p style="word-break: break-word;">
          {{ registration.email }}
        </p>
      </b-col>

      <b-col md="4">
        <h6> Telefone: </h6>
        <p> {{ registration.phone }} </p>
      </b-col>

      <b-col md="4">
        <h6> CPF/CNPJ: </h6>
        <p> {{ registration.document | tax_id }} </p>
      </b-col>

      <b-col md="4">
        <h6> E-CPF: </h6>
        <p> {{ !!registration.has_ecpf ? 'Possui' : 'Não Possui' }}</p>
      </b-col>

      <b-col md="4">
        <h6> Sócios: </h6>
        <p> {{ !!registration.has_partners ? 'Possui' : 'Não Possui' }}</p>
      </b-col>
    </b-row>

    <h4
      v-if="registration.contact"
      class="mt-2"
    >
      Contato
    </h4>
    <b-row
      v-if="registration.contact"
      class="mt-2"
    >
      <b-col md="4">
        <h6> Nome: </h6>
        <p> {{ registration.contact.name }}</p>
      </b-col>

      <b-col

        md="4"
      >
        <h6> Email: </h6>
        <p style="word-break: break-word;">
          {{ registration.contact.email || 'Não possui' }}
        </p>
      </b-col>

      <b-col
        md="4"
      >
        <h6> Celular: </h6>
        <p style="word-break: break-word;">
          {{ registration.contact.cell_phone_number || 'Não possui' }}
        </p>
      </b-col>

      <b-col
        md="4"
      >
        <h6> Telefone: </h6>
        <p style="word-break: break-word;">
          {{ registration.contact.phone_number || 'Não possui' }}
        </p>
      </b-col>

      <b-col md="4">
        <h6> CPF/CNPJ: </h6>
        <p> {{ registration.contact.tax_id | tax_id }}</p>
      </b-col>

    </b-row>

    <h4 class="mt-2">
      Cadastro
    </h4>

    <b-row class="mt-2">
      <b-col md="4">
        <h6> Tipo do Lead: </h6>
        <p> {{ registration.record_type.type }}</p>
      </b-col>

      <b-col md="4">
        <h6> Tipo da Conta: </h6>
        <p> {{ registration.conversion_type }}</p>
      </b-col>

      <b-col md="4">
        <h6> Lead: </h6>
        <p> {{ registration.lead_id || "Lead ainda não foi criado" }}</p>
      </b-col>

      <b-col md="4">
        <h6> Agente Comercial: </h6>
        <p> {{ registration.comercial_agent ? registration.comercial_agent.name : "Não possui agente comercial" }}</p>
      </b-col>

      <b-col md="4">
        <h6> Pré Vendedor: </h6>
        <p> {{ registration.sales_development_representative ? registration.sales_development_representative.name : 'Não possui pré vendedor' }}</p>
      </b-col>

      <b-col md="12">
        <h6> Soluções Associadas: </h6>
        <ul class="pl-1">
          <li
            v-for="product in registration.registration_products"
            :key="product.id"
          >
            {{ product.product.product }}
          </li>
        </ul>
      </b-col>
      <b-col
        md="12"
      >
        <h6> Documentos Invalidados: </h6>
        <ul
          v-if="registration.registration_denied_file.length"
          class="pl-1"
        >

          <li
            v-for="deniedFile in registration.registration_denied_file"

            :key="deniedFile.id"
          >
            {{ deniedFile.vadu_file.name }}
          </li>
        </ul>

        <p v-else>
          Nenhum documento foi invalidado
        </p>
      </b-col>

      <b-col
        md="12"
      >
        <h6> Histórico de Arquivos Enviados: </h6>
        <b-table
          v-if="registration.files.data.length > 0"
          :items="registration.files.data"
          :fields="[{ label: 'Nome', key: 'name' }, { label: 'Data', key: 'updated_at' }]"
        >
          <template #cell(updated_at)="data">
            {{ data.item.updated_at | date_hour }}
          </template>
        </b-table>

        <p v-else>
          Nenhum documento foi enviado
        </p>
      </b-col>
    </b-row>

    <b-button
      variant="primary"
      @click="handleButtonClick"
    >
      Copiar link da visão do cliente
    </b-button>

    <b-button
      v-if="registration.status_id == 9 || registration.status_id == 5"
      variant="primary"
      class="ml-1"
      :href="`/cadastro-online/${registration.link_hash}`"
    >
      Editar cadastro
    </b-button>

  </div>
</template>

<script>
import {
    BRow, BBadge, BCol, BTable, BButton,
} from 'bootstrap-vue';

export default {
    components: {
        BButton,
        BRow,
        BBadge,
        BCol,
        BTable,
    },

    props: {
        statuses: {
            type: Object,
            required: true,
            default: () => {},
        },

        registration: {
            type: Object,
            required: true,
            default: () => {},
        },
    },

    methods: {
        handleButtonClick() {
            navigator.clipboard.writeText(`https://www.portal.grupoviainvest.com.br/cadastro/detalhes/${this.registration.link_hash}`);
            this.modalSuccess('Link copiado com sucesso');
        },
    },
};
</script>
