export default {
    1: {
        text: 'Aprovado',
        variant: 'success',
    },
    2: {
        text: 'Não Aprovado',
        variant: 'danger',
    },
    3: {
        text: 'Em análise',
        variant: 'warning',
    },
    5: {
        text: 'Em processamento',
        variant: 'secondary',
    },
    6: {
        text: 'Enviando Arquivos',
        variant: 'dark',
    },
    9: {
        text: 'Sem Documentação',
        variant: 'primary',
    },
    15: {
        text: 'Documentos Pendentes',
        variant: 'info',
    },
    16: {
        text: 'Sem Documentação',
        variant: 'primary',
    },
    17: {
        text: 'Contatado',
        variant: 'success',
    },
    20: {
        text: 'Projeto',
        variant: 'success',
    },
    21: {
        text: 'Erro Arquivos',
        variant: 'secondary',
    },
};
