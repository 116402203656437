var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-modal',{attrs:{"id":"registration-details-modal","size":"lg","title":_vm.item ? _vm.item.name : '',"hide-footer":""}},[(_vm.item)?_c('details-registration',{attrs:{"id":"registration-details-modal","registration":_vm.item,"statuses":_vm.status}}):_vm._e()],1),_c('b-card',[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Filtro","label-for":"filter"}},[_c('b-form-input',{attrs:{"id":"filter","placeholder":"Filtro"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)],1)],1),_c('b-table',{staticClass:"text-center",attrs:{"hover":"","current-page":_vm.page,"filter":_vm.filter,"fields":[
        { key: 'name', label: 'Razão Social' },
        { key: 'email', label: 'E-mail' },
        { key: 'phone', label: 'Telefone' },
        { key: 'document', label: 'CPF/CNPJ' },
        { key: 'status', label: 'Status'},
        { key: 'user_name', label: 'Operador'},
        { key: 'created_at', label: 'Data de Cadastro'}
      ],"items":_vm.items,"per-page":10,"responsive":"xl"},on:{"row-clicked":_vm.handleRowClick,"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"cell(user_name)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.user.name)+" ")]}},{key:"cell(document)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("tax_id")(data.item.document))+" ")]}},{key:"cell(created_at)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("date")(data.item.created_at))+" ")]}},{key:"cell(status)",fn:function(data){return [_c('b-badge',{attrs:{"variant":_vm.status[data.item.status_id].variant}},[_vm._v(" "+_vm._s(_vm.status[data.item.status_id].text)+" ")])]}},{key:"empty",fn:function(){return [_vm._v(" Nenhum registro foi criado ")]},proxy:true}])}),_c('b-pagination',{staticClass:"mt-2",attrs:{"total-rows":_vm.totalRows,"align":"center","size":"sm","per-page":10},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }