import methods from '@/enums/methods';
import Ramses from '../base/ramses';

class OnlineRegistrationService extends Ramses {
    getProducts() {
        return this.fetch({
            url: 'backoffice/product',
        });
    }

    getAllRegistrations() {
        return this.fetch({
            url: '/registration',
        });
    }

    createLink(data) {
        return this.fetch({
            data,
            method: methods.POST,
            url: '/registration',
        });
    }

    findRegistrationByHash(hash) {
        return this.fetch({
            url: `registration/${hash}/protected`,
        });
    }

    findImportedFilesByRegistrationId(id) {
        return this.fetch({
            url: `registration/files/${id}`,
        });
    }

    updateRegistration(hash, data) {
        return this.fetch({
            url: `registration/${hash}`,
            method: methods.PUT,
            data,
        });
    }

    sendPendingFiles(file, hash) {
        const formData = new FormData();
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(file)) {
            formData.append(key, value);
        }
        return this.fetch({
            url: `registration/${hash}/pending-files`,
            method: methods.POST,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }
}

export default new OnlineRegistrationService();
