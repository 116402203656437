<template>
  <b-container fluid>

    <b-modal
      id="registration-details-modal"
      size="lg"
      :title="item ? item.name : ''"
      hide-footer
    >
      <details-registration
        v-if="item"
        id="registration-details-modal"
        :registration="item"
        :statuses="status"
      />
    </b-modal>
    <b-card>
      <b-row class="mb-2">
        <b-col md="4">
          <b-form-group
            label="Filtro"
            label-for="filter"
          >
            <b-form-input
              id="filter"
              v-model="filter"
              placeholder="Filtro"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        hover
        :current-page="page"
        class="text-center"
        :filter="filter"
        :fields="[
          { key: 'name', label: 'Razão Social' },
          { key: 'email', label: 'E-mail' },
          { key: 'phone', label: 'Telefone' },
          { key: 'document', label: 'CPF/CNPJ' },
          { key: 'status', label: 'Status'},
          { key: 'user_name', label: 'Operador'},
          { key: 'created_at', label: 'Data de Cadastro'}
        ]"
        :items="items"
        :per-page="10"
        responsive="xl"
        @row-clicked="handleRowClick"
        @filtered="onFiltered"
      >
        <template #cell(user_name)="data">
          {{ data.item.user.name }}
        </template>
        <template #cell(document)="data">
          {{ data.item.document | tax_id }}
        </template>
        <template #cell(created_at)="data">
          {{ data.item.created_at | date }}
        </template>
        <template #cell(status)="data">
          <b-badge :variant="status[data.item.status_id].variant">
            {{ status[data.item.status_id].text }}
          </b-badge>
        </template>

        <template #empty>
          Nenhum registro foi criado
        </template>
      </b-table>

      <b-pagination
        v-model="page"
        :total-rows="totalRows"
        align="center"
        size="sm"
        class="mt-2"
        :per-page="10"
      />
    </b-card>
  </b-container>
</template>

<script>
import {
    BContainer, BCard, BTable, BBadge, BPagination, BFormInput, BRow, BCol, BFormGroup,
} from 'bootstrap-vue';
import DetailsRegistration from './DetailsRegistration.vue';

import OnlineRegistrationService from '@/service/online_registration';

import minuteToMs from '@/utils/tools/minute-to-ms';

import { callLoading } from '@/service/utilities';
import repeatByTime from '@/utils/tools/repeat-by-time';

import status from '@/enums/statusToBadge';

export default {
    components: {
        BCard,
        BFormGroup,
        BTable,
        BContainer,
        BBadge,
        BPagination,
        DetailsRegistration,
        BFormInput,
        BRow,
        BCol,
    },

    data() {
        return {
            items: [],
            item: null,
            status,
            page: 1,
            filter: '',
            totalRows: 0,
            intervalId: null,
        };
    },

    async mounted() {
        callLoading(true);
        await this.fetch();
        callLoading(false);

        this.intervalId = repeatByTime(this.fetch, minuteToMs(2.5));
    },

    beforeDestroy() {
        clearInterval(this.intervalId);
    },

    methods: {
        onFiltered(items) {
            this.totalRows = items.length;
        },

        async handleRowClick(row) {
            callLoading(true);
            const [
                { data: responseRegistration },
                { data: responseRegistrationFiles },
            ] = await Promise.all([
                OnlineRegistrationService.findRegistrationByHash(row.link_hash),
                OnlineRegistrationService.findImportedFilesByRegistrationId(row.id),
            ]);

            this.item = {
                ...responseRegistration,
                files: responseRegistrationFiles,
            };
            callLoading(false);
            this.$bvModal.show('registration-details-modal');
        },

        async fetch() {
            const { data } = await OnlineRegistrationService.getAllRegistrations();
            this.totalRows = data.data.length;

            this.items = data.data.map(item => ({
                id: item.id,
                status_id: item.status_id,
                created_at: item.created_at,
                document: item.document,
                email: item.email,
                name: item.name,
                phone: item.phone,
                link_hash: item.link_hash,
                user: {
                    name: item.user.name,
                },
            })).reverse();
        },
    },
};
</script>
